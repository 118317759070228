* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Rubik", sans-serif;
}

a {
  text-decoration: none !important;
  display: inline-block;
}


.navigation {
  background-color: #1b1f29;
  border-bottom: 1px solid hsla(0, 0%, 100%, .2);
  color: #fff;
  padding: 0.25rem 0;
}


.loadingpage {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center !important;
}


.logo-img {
  height: 70px;
}

.ser-ico {
  height: 27px;
  width: 27px;
  margin-top: 2px;
}

/* .search p {
  font-size: 22px;
  font-weight: 600;
  color: #ffff;
  margin-left: 8px;
} */




.search {

  padding-top: 20px;
}


.e p {
  font-size: 22px;
  font-weight: 600;
  color: #ffff;
  margin-left: 8px;
}

.e p {
  padding-top: 20px;
}


.maiin {
  margin-top: 100px;
}




.l-title h1 {
  margin-top: -50px;
  font-size: 40px !important;
  font-weight: 700;
  font-family: 'Cabin', sans-serif;
}

.l-title {
  border-bottom: 3px solid #d7282f !important;
  width: 122px;
}

.l-title2 {
  border-bottom: 3px solid #d7282f !important;
  width: 125px;
}
.l-title2  h1{
  font-size: 32px;
  margin-top: -50px;
}



.form {
  margin-top: 35px;
}

.from-p {
  color: #5d738d;
  font-size: 13px;
  font-weight: 500;
}

.cust_inp {
  height: 60px;
  margin-top: -5px;
}

.mar {
  margin-top: 40px;
}


.form-check-label {
  font-size: 18px !important;
  font-weight: 500;
}

.button {
  margin-top: 30px;
  background-color: #f74356;
  color: #ffff;
  padding: 8px 29px;
  border-radius: 100px;
  font-size: 20px;
  font-weight: 700;
  font-family: 'Cabin', sans-serif;
  border: 2px solid #f74356 !important;

}

.button:hover {
  background-color: #c32838;
  color: #ffff;
  padding: 8px 29px;
  border-radius: 100px;
  font-size: 20px;
  font-weight: 700;
  font-family: 'Cabin', sans-serif;
  border: 2px solid #f74356 !important;

}

.out-fr {
  margin-top: 60px;
}


.button-bt {
  background-color: #ffff;
  color: #f74356;
  padding: 6px 19px;
  border-radius: 100px;
  font-size: 16px;
  font-weight: 700;
  font-family: 'Cabin', sans-serif;
  border: 1px solid #f74356 !important;
}




.button-pro {
  border: 1px solid #fd4a5c !important;
  background: #ffff;
  color: #fd4a5c;
  padding: 5px 18px;
  border-radius: 30px;
  font-weight: 500;
}
.button-pro2 {
  border: 1px solid #fd4a5c !important;
  background: #ffff;
  color: #fd4a5c;
  padding: 5px 18px;
  border-radius: 30px;
  font-weight: 500;
  margin-left: 10px;
}




.sign p {
  margin-top: 25px;
  font-size: 18px;
  color: #414141;
  line-height: 18px;
}

.sign p a {

  margin-top: 10px;
  font-size: 15px;
  color: #414141;
  border-bottom: 1px solid #d7282f;
}
.sign p {

  margin-top: 10px;
  font-size: 15px;
  color: #414141;
}

.saa {
  margin-top: 110px;
}


.footer {
  background: #1b1f29;
  border-bottom: 2px solid #d7282f;
  color: #f7f9fb;
  font-size: .9rem;
  line-height: 2rem;
  margin-top: 2rem;
  padding: 2rem 0;
}

.footer-log {
  height: 113px;
}


.footer-logo {
  height: 50px;
  margin-top: 30px;
}


.footer-link a {
  color: #ffff;
  font-size: 18px;
  margin-top: 10px;
}

.footer-link a:hover {
  border-bottom: 1px solid #ffff;
}

.hol-bg {
  background: url(../src/images/bg.png);
  padding-bottom: 100px;
}

.main-box {
  background: #ffff;
  padding: 50px;
  border-radius: 1.5rem;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, .11);
}

.wel-text h1 {
  color: #d7282f;
  font-weight: 500;
  font-size: 60px;
  text-align: center;
}

.wel-text p {
  color: #5d738d;
  font-size: 23px;
  text-align: center;
}

.round p {
  border: 2px solid #5d738d;
  color: #5d738d;
  border-radius: 32px;
  height: 32px;
  width: 32px;
  padding: 2px 0px 0px 10.5px;
}

.round2 p {
  border: 2px solid #d7282f;
  color: #d7282f;
  border-radius: 32px;
  height: 32px;
  width: 32px;
  padding: 2px 0px 0px 10.5px;
}


.act {
  background-color: #d7282f;
  color: #ffff !important;
  border: 2px solid #d7282f !important;
  color: #d7282f;
  border-radius: 32px;
  height: 32px;
  width: 32px;
  padding: 2px 0px 0px 10.5px;
}



.divider {
  height: 2px !important;
  margin: 15px 5px !important;
  width: 0px !important;
  padding: 0px 10px 0px 10px;
  background: #5d738d !important;
  color: #ffff;
}

.active {
  height: 2px !important;
  margin: 15px 5px !important;
  width: 0px !important;
  padding: 0px 10px 0px 10px;
  background-color: #d7282f !important;
  color: #ffff;
}


.did {
  margin-left: 100px;
}

.img-grl {
  margin: -700px 0px 0px 900px;
}

.boxx-top {
  padding: 0px 200px 0px 200px !important;
  text-align: center;
}

.boxx-top h3 {
  font-size: 38px;
}

.boxx-top p {
  font-size: 25px;
}

.boxx-top2 {
  padding: 0px 10px 0px 10px !important;
  text-align: center;
}

.boxx-top2 h3 {
  font-size: 38px;
}

.boxx-top2 p {
  font-size: 20px;
  color: #212a33;
}

.wel-img {
  margin-left: 45%;
  margin-top: -120px;
}


.bt-bg {
  padding: 20px;
  background-color: #fff3cd;
  border: 1px solid #ffeeba;
  border-radius: 5px;
}

.bt-bg p {
  font-size: 19px;
  color: #927317;
}

.left-text {
  border-right: 2px solid #d5dde4;
}

.left-text .p1 {
  font-size: 20px;
  margin-top: 15px;
}

.left-text .text-muted {
  font-size: 20px;

}

.left-text ul li strong {
  font-size: 23px;
}

.span-p {
  font-size: 20px;
}

.rs-p {
  font-size: 20px;
}

.font-italic {
  font-size: 20px;
  font-style: italic;
}

.decorated {
  color: #010101;
  border-bottom: 1px solid #d7282f;
}

.blu-box {
  padding: 30px;
  background-color: #d4e6ff;
  border: 2px solid #c2ddff;
  border-radius: 5px;
  margin-top: 20px;
}

.blu-box p {
  font-size: 20px;
  color: #215498;
}

.blu-box .bb-p {
  font-size: 20px;
  color: #215498;
  font-weight: 600;
}



.cus {
  border: 2px solid rgb(216, 216, 216);
  border-radius: 3px;
  padding: 13px 0px 0px 15px;
  height: 60px;
}

.in-p {
  font-size: 24px;
  color: #959595;
}

.in-p2 {
  font-size: 20px;
  color: #959595;
}


.in-btn {
  background-color: #f3eeee;
  padding: 0px 40px;
  margin-top: -14px;
  height: 60px;
  font-size: 22px;
  border: 2px solid rgb(216, 216, 216);
}

.tag-p {
  font-size: 19px;
  margin-top: 30px;
  margin-bottom: -5px;
}


.tag-p2 {
  font-size: 19px;
  margin-top: -25px;
}

.mmmmm {
  color: #5d738d !important;

}

.inp-t {
  height: 180px;
  border: 2px solid rgb(216, 216, 216);
  border-radius: 5px;
  padding: 10px;
  font-size: 18px;
  color: #959595;
}

.button-btn {
  background: #d7282f;
  color: #f7f9fb;
  padding: 23px 0px;
  border-radius: 23px !important;
  font-size: 22px;
  font-weight: 600;
  margin-top: -0px !important;
}

.button-btn2 {
  background: #d7282f;
  color: #f7f9fb;
  padding: 10px 0px;
  border-radius: 5px !important;
  font-size: 22px;
  font-weight: 600;
  margin-top: -0px !important;
}


.button-btn3 {
  background: #d7282f;
  color: #f7f9fb;
  padding: 20px 60px;
  border-radius: 30px !important;
  font-size: 22px;
  font-weight: 600;
  margin-top: -0px !important;
}


.re-d {
  background-color: #d7282f;
  width: 370px;
  margin: auto;
  padding: 0px 5px;
  border-radius: 5px;

}

.re-d p {

  color: #ffff;
  font-size: 18px;
  text-align: center;
}


.yollo {
  background-color: #fff3cd;
  border: 2px solid #ffeeba;
  color: #927317;
  border-radius: 5px;
  padding: 10px 10px 5px 10px;
}

.yollo p {

  font-size: 20px;
}

.veri-p {
  font-size: 20px;
}

.input-cal {
  margin-top: 90px;
}

.img-bor {
  border-radius: 5px;
}

.inp-lable {
  font-size: 18px;
  font-weight: 700;
}

.input-fuild {
  width: 100%;
  border-radius: 0.3rem;
  font-size: 1.25rem;
  height: 70px;
  line-height: 2;
  border: 1px solid #d0d0d0;
  padding: 0.5rem 1rem;
}


.upp-i{
  height: 130px;
    width: 100px;
    margin-bottom: 30px;
}




@media (max-width: 576px) {
  .search {
    display: none !important;
  }

  .e P {
    margin-top: -4px;
    font-size: 15px !important;
  }


  .l-title h1 {
    font-size: 27px !important;
  }

  .l-title {
    border-bottom: 3px solid #d7282f !important;
    width: 75px;
    margin-top: -70px;
  }


  .l-title2 {
    border-bottom: 3px solid #d7282f !important;
    width: 95px;
    margin-top: 90px !important;
  }



  .sign p a {

    margin-top: 10px;
    font-size: 16px;
    color: #414141;
    border-bottom: 1px solid #d7282f;
  }


  .sign p {

    margin-top: 10px;
    font-size: 16px;
    color: #414141;
    line-height: 23px;
  }


  .button-pro {
    border: 1px solid #fd4a5c !important;
    background: #ffff;
    color: #fd4a5c;
    padding: 5px 18px;
    border-radius: 30px;
    font-weight: 500;
  }

  .button-pro2 {
    border: 1px solid #fd4a5c !important;
    background: #ffff;
    color: #fd4a5c;
    padding: 5px 18px;
    border-radius: 30px;
    font-weight: 500;
    margin-left: 10px;
  }

  .prividerbt {
    margin-top: 0px !important;
  }



  .logo-img {
    height: 51px;
  }


  .from-p {
    font-size: 14px;
  }

  .firstp {
    margin-top: -10px;
  }

  .mar {
    margin-top: 30px;
  }

  .checkbbox {
    margin-top: 30px !important;
  }

  .cust_inp {
    height: 57px;
    margin-top: -10px;
  }

  .form-check-label {
    font-size: 16.5px !important;
    margin-top: 2px;
  }

  .button {
    margin-top: 30px;
    background-color: #f74356;
    color: #ffff;
    padding: 8px 30px;
    border-radius: 100px;
    font-size: 20px;
    font-weight: 700;
    font-family: 'Cabin', sans-serif;
    border: 2px solid #f74356 !important;

  }

  .button:hover {
    background-color: #c32838;
    color: #ffff;
    padding: 15px 40px;
    border-radius: 100px;
    font-size: 20px;
    font-weight: 700;
    font-family: 'Cabin', sans-serif;
    border: 2px solid #f74356 !important;

  }


  .button-bt {
    background-color: #ffff;
    color: #f74356;
    padding: 5px 30px;
    border-radius: 25px;
    font-size: 16px;
    font-weight: 700;
    font-family: 'Cabin', sans-serif;
    border: 1px solid #f74356 !important;
  }

  .out-fr {
    margin-top: 50px;
  }


  .l-title2 h1 {
    font-size: 25px;
  }


  .sign {
    margin-top: 50px;
  }


  .boxx-top {
    padding: 0px 0px 0px 0px !important;
    text-align: center;
  }


  .main-box {
    padding: 20px;
  }

  .img-grl {
    margin: -1150px 0px 0px 230px;
    height: 180px;
  }

  .ppppppp {
    padding-left: 10px !important;
  }

  .did {
    margin-left: 0px !important;
    padding-left: 100% !important;
  }


  .cus {
    border: 2px solid rgb(216, 216, 216);
    border-radius: 3px;
    padding: 7px 0px 5px 15px;
    height: 50px;
  }

  .in-p {
    font-size: 16px;
    color: #959595;
  }


  .in-p2 {
    font-size: 16px;
    color: #959595;
    margin-top: -5px;
    line-height: 20px;
  }


  .in-btn {
    background-color: #f3eeee;
    padding: 0px 10px;
    margin-top: -9px;
    height: 50px;
    font-size: 18px;
    border: 2px solid rgb(216, 216, 216);
  }

  .upp-i {
    height: 130px;
    width: 100px;
    margin-bottom: 30px;
  }



  .button-btn {
    background: #d7282f;
    color: #f7f9fb;
    padding: 15px 0px;
    border-radius: 23px !important;
    font-size: 18px;
    font-weight: 600;
    margin-top: -0px !important;
  }


  .re-d {
    background-color: #d7282f;
    width: 100%;
    margin: auto;
    padding: 0px 5px;
    border-radius: 5px;

  }


  .re-d p {

    color: #ffff;
    font-size: 14px;
    text-align: center;
  }

  .tooooooppp {
    font-size: 19px !important;
  }


  .input-cal {
    margin-left: 0px !important;
    margin-top: 30px;
  }


  .input-fuild {
    width: 100%;
    border-radius: 0.3rem;
    font-size: 1.25rem;
    height: 55px;
    line-height: 2;
    border: 1px solid #d0d0d0;
    padding: 0.5rem 1rem;
  }

  .wel-img {
    margin-left: 40%;
    margin-top: -60px;
  }

  .button-btn3 {
    background: #d7282f;
    color: #f7f9fb;
    padding: 15px 50px;
    border-radius: 30px !important;
    font-size: 18px;
    font-weight: 600;
    margin-top: -0px !important;
    margin-left: 5%;
  }


  .footer {
    background: #1b1f29;
    border-bottom: 2px solid #d7282f;
    color: #f7f9fb;
    font-size: .9rem;
    line-height: 2rem;
    margin-top: -40px;
    padding: 2rem 0;
  }

  .fot-flex {
    display: flex;
  }

  .footer-log {
    height: 57px;
  }

  .footer-logo {
    height: 70px;
    width: 130px;
    margin-top: -5px;
    margin-left: 24px;
  }

  .footer-link a {
    font-size: 14px;
    line-height: 10px;
  }

  .copyW {
    text-align: center !important;
    margin-top: 5 0px;
  }


  .inp-t{
    font-size: 15px;
  }



}








@media (min-width: 576px) and (max-width: 767.98px) {
  .search {
    display: none !important;
  }

  .e P {
    margin-top: -4px;
    font-size: 16px !important;
  }



  .logo-img {
    height: 55px;
  }

  .button {
    background-color: #f74356;
    color: #ffff;
    padding: 15px 70px;
    border-radius: 100px;
    font-size: 23px;
    font-weight: 700;
    font-family: 'Cabin', sans-serif;
    border: 2px solid #f74356 !important;

  }

  .button:hover {
    background-color: #c32838;
    color: #ffff;
    padding: 15px 70px;
    border-radius: 100px;
    font-size: 23px;
    font-weight: 700;
    font-family: 'Cabin', sans-serif;
    border: 2px solid #f74356 !important;

  }

  .sign {
    margin-top: 50px;
  }

  .in-p2 {
    font-size: 17px;
    color: #959595;
    margin-top: 0px;
    line-height: 20px;
  }


}